import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'

import { useSiteState } from '../lib/siteContext'

import { QueryFragments } from '../GraphQl/queryFragments' // eslint-disable-line

// Components
import { PageMeta } from '../components/pageMeta'
import ModuleZone from '~moduleZone'

const ArticleYacht = ({ pageContext, data: { page, categories } }) => {
  const { title, seo, pageModules } = page || {}
  const { homeHeader, setHomeHeader, setLogoColor } = useSiteState()

  useEffect(() => {
    if (homeHeader === true) {
      setHomeHeader(false)
    }
    document.documentElement.style.setProperty(`--color-logo`, '#18227B')
    document.documentElement.style.setProperty(`--doc-bg`, '#ffffff')
    document.documentElement.style.setProperty(`--doc-text`, '#000000')
  }, [setHomeHeader, homeHeader, setLogoColor])

  return (
    <>
      <PageMeta {...seo} />
      <div class="grid-std pt-40 lg:pt-72 xl:pt-bannerTop pb-space-s">
        <h1 className="col-start-2 col-end-8 lg:col-start-3 lg:col-end-17 heading uppercase text-h5 md:text-h4 lg:text-h3">
          {title}
        </h1>
      </div>
      {pageModules && <ModuleZone {...pageModules} />}
      {pageContext.next != null && (
        <div className="w-full flex justify-end text-h5 lg:text-h4 text-current px-gutter">
          <Link
            className="lg:w-1/2"
            node={pageContext.next}
            to={`/news/${pageContext.next.slug.current}`}
          >
            <span className="heading text-current uppercase pb-4 text-base">
              Next Article
            </span>
            <br />
            {pageContext.next.title}
          </Link>
        </div>
      )}
    </>
  )
}

export default ArticleYacht

export const pageQuery = graphql`
  query articleYachtQuery($slug: String!) {
    page: sanityArticleYacht(slug: { current: { eq: $slug } }) {
      title
      _createdAt(formatString: "d MMM YYYY")
      seo {
        ...SEO
      }
      pageModules: modules {
        ...ArticleModules
      }
    }
  }
`
